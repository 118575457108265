
/*----- RESET -----*/

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #F6F9FC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*----- IMPORTS ------*/

@import '~antd/dist/antd.css';



/*----- END ------*/
